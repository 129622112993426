import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>{`"Mohsin and his team are AMAZING! They were able to deliver my app in Flutter with a strong backend successfully and within the time mentioned. What I appreciated about them were their transparency and professionalism. They take you through the whole process and keep you in the loop. Their communication is on top, they provide a build every week, and they don't hesitate to ask any questions they might have. What surprised me the most was their ability to understand my product and propose new ideas to make the app even better. They're more than developers! Thank you so much for your work! I highly appreciate it! I can't wait for the next features :)"`}</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Guillaume Maarek </span> - FOUNDER, AZ Sports.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>{`"Great journey working with DNA for our website platform - front and backend. Easy and clear communication from the start, always quick to respond, as well as going above and beyond what was asked. Appreciate all the hard work! Highly recommend working with DNA."`}</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Janet Nakano </span> - Co Founder, Humanava</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>{`"Patient, respectful, fair, consistent, and professional. Team really loves what they do. Their energy and passion will rub off on you. There was not a penny i didn't feel was well spent."`}</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Xavier Mountain </span> - CEO, Perceptive Focus</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/az_stretch.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/humanava.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/pf.png" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;