import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiPhone, FiLayout, FiCloud, FiBox } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiBox />,
        title: 'Software Consultation',
        description: 'We provide an end to end development roadmap to make your idea a reality.'
    },
    {
        icon: <FiMonitor />,
        title: 'Cross-platform Mobile Apps',
        description: 'Using Flutter to develop awesome experiences with our own state management framework.'
    },
    {
        icon: <FiLayers />,
        title: 'Web App Development',
        description: 'Using NextJS or ReactJS. Bundled with some awesomeness of FramerMotion and TailwindCSS.'
    },
    {
        icon: <FiLayout />,
        title: 'UI/UX',
        description: 'We can take a bad drawing from you and turn it into a mindblowing product design.'
    },
    { 
        icon: <FiCloud />,
        title: 'Backend Solutions',
        description: 'We work with secure cloud based serverless solutions and full stack custom solutions on demand.'
    },
    
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList;
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
