import React, { Component , Fragment } from "react";
import { Parallax } from "react-parallax";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import HeaderThree from "../component/header/HeaderThree";
import Header from "../component/header/Header";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team, { MarketingTeam } from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const image1 =
  "/assets/images/portfolio/paralax/bg-image-3-overlay.jpg";

  const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Digital Nomad Agency',
        description: 'At DNA, our goal is simple: to create and deliver WOW.\nYour vision guides and inspires us, and our job is to take it to the next level.',
        buttonText: 'Book Consultation',
        buttonLink: '/contact'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Flutter',
        title: 'AZ-Strech',
        url: 'https://azsport.app/az-stretch/',
    },
    {
        image: 'image-2',
        category: 'NextJS | Django',
        title: 'EN Web',
        url: 'https://endiscover.app',
    },
    {
        image: 'image-3',
        category: 'Flutter',
        title: 'Traetelo',
        url: 'https://apps.apple.com/us/app/traetelo-fashion-shopping/id1525570854',
    },
    {
        image: 'image-4',
        category: 'NextJS | StrapiJS',
        title: 'Humanava',
        url: 'https://humanava.com'
    },
    {
        image: 'image-5',
        category: 'Manifestie',
        title: 'Flutter',
        url: 'https://manifestie.app'
    },
    
]

class CreativeAgency extends Component{
    render(){
       
        return(
            <Fragment>
                <div className="active-dark">
                <Helmet pageTitle="Digital Nomad Agency" />
                <HeaderThree homeLink="/" logo="light" color="color-black"/>
                    {/* Start Slider Area   */}
                
                <div id="home" className="slider-activation slider-creative-agency">
                    <Parallax bgImage={image1} strength={1000}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title animated-title-text">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" target="_blank" rel="noopener noreferrer" href="https://calendly.com/jens-94/30min-discovery">{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>
                {/* End Slider Area   */}


                {/* Start Service Area  */}
                <div id="service" className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 

                {/* Start Portfolio Area */}
                <div id="portfolio" className="portfolio-area pt--120 pb--140 bg_color--5">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title">Our Recent Work</h2>
                                        <p>Showcasing some of our recent creations here</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" target="_blank" rel="noopener noreferrer" href={`${value.url}`}>View</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">So far...</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div id="team" className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Core Team</h2>
                                    <p>The wizards that would help take your idea to the next level 🚀</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>

                <div id="team" className="rn-team-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Marketing Team</h2>
                                    <p>The wizards that reach out to you and bring you to us 🚀</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <MarketingTeam column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div id="reviews" className="rn-testimonial-area bg_color--1 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}
                
                {/* Contact section */}
                <div id="contact" className="rn-team-area ptb--120 bg_color--5">
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact over upwork</h4>
                                        <p><a href="https://www.upwork.com/ag/digitalnomadacademy/" target="_blank" rel="noopener noreferrer">Digital Nomad Agency - Upwork</a></p>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:admin@gmail.com">info@dnadeveloper.com</a></p>
                                       
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p><a href="#">Tallinn, Estonia</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                </div>

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </div>
                

            </Fragment>
        )
    }
}

export default CreativeAgency;