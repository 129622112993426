import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    {
        images: 'mohsin',
        title: 'Mohsin Ajmal',
        designation: 'Development Lead',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/iosandroidappexpert/'
            },
        ]
    },
    {
        images: 'matej',
        title: 'Matej Simunic',
        designation: 'Consultant | Architect | Shaman',
        socialNetwork: [
            
        ]
    },
    
    // {
    //     images: 'leo',
    //     title: 'Leo Radočaj',
    //     designation: 'Mobile Development Wizard',
    //     socialNetwork: [
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: 'https://www.linkedin.com/in/leo-rado%C4%8Daj-bb590a165/'
    //         },
           
    //     ]
    // },
    {
        images: 'yuri',
        title: 'Yurii Funkendorf',
        designation: 'UI/UX Guru | Business Partner',
        socialNetwork: [
          
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/funkendorf/'
            },
            
        ]
    },
    // {
    //     images: 'stephanie',
    //     title: 'Stephanie',
    //     designation: 'Business Developer | Anime Lover',
    //     socialNetwork: [
             
    //     ]
    // }
    
];

let MarketingTeamContent = [
    {
        images: 'jens',
        title: 'Jens Johnsen',
        designation: 'Marketing Lead | Morii',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/jens-johnsen-a969b1a4/'
            },
        ]
    },
    {
        images: 'gustavo',
        title: 'Gustavo Vilera',
        designation: 'Technical Sales Closer | Morii-DNA',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/gustavovilera/'
            },
        ]
    },
]


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/${value.images}.png`} alt={`${value.title}`}/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a target="_blank" rel="noopener noreferrer" href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export class MarketingTeam extends Component {
    render(){

        const {column} = this.props;
        return(
            <React.Fragment>
                {MarketingTeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/${value.images}.png`} alt={`${value.title}`}/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a target="_blank" rel="noopener noreferrer" href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    } 
}

export default Team;
